(function($) {
	
	var $menu = $('.wp-block-metropolis-menu.is-expandable'),
		$toggles = $menu.find('.menu > .menu-item-has-children > a, .menu > .menu-item-has-children > .sub-menu > .menu-item-has-children > a');
			
	$toggles.on('click', function(e) {
		var $subMenu = $(this).siblings('.sub-menu');
		if( $subMenu.is(':hidden') ) {
			e.preventDefault();
			$subMenu.slideToggle();
			$(this).parent().addClass('toggled');
		}
	});
	
})(jQuery);